import authService from '../../../services/auth';

export default {
  name: 'forgotPassword',
  data() {
    return {
      mode: 'request',
      token: '',
      errorMsg: '',

      // Password reset form
      requestingPassword: false,
      passwordRequestForm: {
        email: ''
      },
      passwordRequestRules: {
        email: [
          {
            required: true,
            message: 'Email is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 150,
            message: 'Email can not exceed 150 characters.',
            trigger: 'blur'
          }
        ]
      },

      // Password reset form
      resettingPassword: false,
      resetPasswordForm: {
        token: '',
        password: 'sdf',
        confirmPassword: 'sfsdfsfdfds'
      },
      resetPasswordFormRules: {
        password: [
          {
            required: true,
            message: 'Password is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 50,
            message: 'Password can not exceed 50 characters.',
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: 'Password is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 50,
            message: 'Password can not exceed 50 characters.',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    //#region  --------- Start: Send PAssword Reset Link ----------------

    async validatePasswordResetRequest() {
      return new Promise((resolve) => {
        this.$refs.passwordRequestForm.validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    async sendPasswordResetLink() {
      try {
        this.errorMsg = null;

        // Validate Form
        let isValidData = await this.validatePasswordResetRequest();
        if (!isValidData) return;

        this.requestingPassword = true;

        let result = await authService.sendPasswordResetLink(this.passwordRequestForm);
        if (result.data.success) {
          this.mode = 'success';
        } else {
          this.errorMsg = result.data.message;
        }

        this.requestingPassword = false;
      } catch (err) {
        console.error(err);
        //Something is wrong, please contact support.
        this.errorMsg = `Something's wrong. Please contact our support team.`;
        this.requestingPassword = false;
      }
    },

    //#endregion  --------- End: Send PAssword Reset Link ----------------

    //#region  --------- Start: Generate New Password ----------------

    async validateResetPasswordForm() {
      return new Promise((resolve) => {
        this.$refs.resetPasswordForm.validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    async createNewPassword() {
      try {
        this.errorMsg = null;

        // Validate Form
        let isValidData = await this.validateResetPasswordForm();
        if (!isValidData) return;

        // Check for password match.
        if (this.resetPasswordForm.password != this.resetPasswordForm.confirmPassword) {
          this.errorMsg = `Password doesn't match.`;
          return;
        }

        this.resettingPassword = true;

        let result = await authService.resetPassword(this.resetPasswordForm);
        if (result.data.success) {
          this.mode = 'success';
        } else {
          this.errorMsg = result.data.message;
        }

        this.resettingPassword = false;
      } catch (err) {
        console.error(err);
        //Something is wrong, please contact support.
        this.errorMsg = `Something's wrong. Please contact our support team.`;
        this.resettingPassword = false;
      }

      // this.generatingPassword = true;
      // this.errorMsg = '';
      // this.successMsg = '';
      // // console.log(this.companyCode)
      // var params = {
      //     newPass: this.newPass,
      //     token: this.$route.query.token,
      // };

      // event.preventDefault();
      // // console.log(this.newPass, this.confirmPass);
      // if (this.newPass === this.confirmPass) {
      //     createNewPass(params).then((result) => {
      //         if (result.data.success) {
      //             // this.$router.replace({'token': null});
      //             this.$router.push({ name: 'Login', query: { successMsg: result.data.message } });
      //             this.successMsg = result.data.message;
      //         } else {
      //             //Show invalid error message
      //             this.errorMsg = result.data.message;
      //         }
      //         this.generatingPassword = false;
      //     }).catch((error) => {
      //         //Something is wrong, please contact support.
      //         this.errorMsg = "Something is wrong, please contact support.";
      //         this.generatingPassword = false;
      //     });
      // } else {
      //     this.errorMsg = "Passwords didn't match.";
      //     this.generatingPassword = false;
      // }
    }

    //#endregion  --------- Start: Generate New Password ----------------
  },

  created() {
    // Check if token is available.
    if (this.$route.query && this.$route.query.token) {
      this.token = this.$route.query.token;
      this.resetPasswordForm.token = this.$route.query.token;
    }
  }
};
