<template>
  <div id="forgetPassPage">
    <div id="forgetPassBox">
      <!-- Logo -->
      <div class="logoContainer">
        <img class="logoIcon" src="@/assets/images/logo-full.png" />
      </div>

      <!-- Create New Password Form -->
      <!--       
      <b-form v-if="this.$route.query.token" class="forgetPassForm" @submit="onCreateNewPassSubmit">
        <br />
        <b-form-group id="confirmPasswordInputGroup" label="New Password" label-for="confirmPasswordInput">
          <el-input id="confirmPasswordInput" type="password" v-model="newPass" required></el-input>
        </b-form-group>
        <b-form-group id="passwordInputGroup" label="Confirm Password" label-for="passwordInput">
          <el-input id="passwordInput" type="password" v-model="confirmPass" required></el-input>
        </b-form-group>
        <div class="buttonContainer">
          <el-button native-type="submit" type="primary" class="el-button el-button--primary" style="width: 200px" :loading="generatingPassword">Generate Password</el-button>
        </div>
      </b-form> -->

      <div v-if="token" class="resetPasswordSection">
        <div class="sectionHeader" v-if="mode == 'request'">
          <div class="header1">Set New Password</div>
        </div>
        <div v-if="errorMsg != ''" class="errorDiv">
          {{ errorMsg }}
        </div>

        <el-form v-if="mode == 'request'" ref="resetPasswordForm" class="forgetPassForm" :model="resetPasswordForm" :rules="resetPasswordFormRules">
          <el-form-item label="New Password" prop="password">
            <el-input type="password" v-model="resetPasswordForm.password" placeholder="Enter New Password" maxlength="150"></el-input>
          </el-form-item>
          <el-form-item label="Confirm New Password" prop="password">
            <el-input type="password" v-model="resetPasswordForm.confirmPassword" placeholder="Confirm New Password" maxlength="150"></el-input>
          </el-form-item>
          <div class="buttonContainer">
            <el-button @click="createNewPassword" type="primary" :loading="resettingPassword"> Set New Password</el-button>
            <br /><br />
            <div class="loginLinkSection">
              Remember your password?
              <el-button @click="$router.push({ name: 'Login' })" type="text"> Login </el-button>
            </div>
          </div>
        </el-form>

        <div class="successDiv" v-if="mode != 'request'">
          <!-- <img src="https://static.growlytics.in/dashboard-assets/assets/img/forgot-pass/password-reset-success.png" /> -->
          <i class="checkIcon el-icon-success"></i>
          Password changed successfully.
          <el-button @click="$router.push({ name: 'Login' })" type="success"> Login Now </el-button>
        </div>
      </div>

      <!-- Send Rest Link Form -->
      <div v-else class="passwordRequestSection">
        <div class="sectionHeader" v-if="mode == 'request'">
          <div class="header1">Forgot Password?</div>
          <img src="https://static.growlytics.in/dashboard-assets/assets/img/forgot-pass/request-pass.png" />
          <div class="header2">Enter the email address associated with your account.</div>
        </div>
        <div v-if="errorMsg != ''" class="errorDiv">
          {{ errorMsg }}
        </div>
        <el-form v-if="mode == 'request'" ref="passwordRequestForm" class="forgetPassForm" :model="passwordRequestForm" :rules="passwordRequestRules">
          <el-form-item label="Email" prop="email">
            <el-input v-model="passwordRequestForm.email" placeholder="Enter Email Id" maxlength="150"></el-input>
          </el-form-item>
          <div class="buttonContainer">
            <el-button @click="sendPasswordResetLink" type="primary" :loading="requestingPassword"> Send Password Reset Link</el-button>
            <br /><br />
            <div class="loginLinkSection">
              Remember your password?
              <el-button @click="$router.push({ name: 'Login' })" type="text"> Login </el-button>
            </div>
          </div>
        </el-form>

        <div class="successDiv" v-if="mode != 'request'">
          <img src="https://static.growlytics.in/dashboard-assets/assets/img/forgot-pass/email-sent.png" />
          Password reset instructions sent. <br />
          Please check your inbox.
          <el-button @click="$router.push({ name: 'Login' })" type="primary"> Login Now </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ForgetPassComponent from './forgetPassComponent';
export default ForgetPassComponent;
</script>

<style lang="scss" src="./forgetPass.scss"></style>
